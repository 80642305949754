import Vue from "vue";
import Router from "vue-router";
import store from "../store";
Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if ("slug" in to.params) {
      if (from.params.slug === to.params.slug) {
        return null;
      }
    } else {
      // console.log("Route indevvv footer ====>", savedPosition);
      return { x: 0, y: 0 };
    }
  },

  routes: [
    {
      path: "",
      component: () => import("@/layouts/frontend/FullPage.vue"),
      children: [
        {
          path: "/",
          name: "home",
          component: () => import("@/views/Home.vue"),
          meta: {
            breadcrumb: "Home",
          },
        },
        {
          path: "product/:slug/:type",
          name: "product",
          component: () => import("@/components/Product/ProductDetails.vue"),
          meta: {
            breadcrumb() {
              const { path } = this.$route;

              return {
                label: path,
                parent: "home",
              };
            },
          },
        },
        {
          path: "category/:slug",
          name: "category",
          component: () => import("@/components/Category/CategoryProduct.vue"),
          meta: {
            breadcrumb: {
              label: "Category",
              parent: "Params cat",
            },
          },
        },
        {
          path: "brand/:slug",
          name: "brand",
          component: () => import("@/components/Brand/BrandProduct.vue"),
          meta: {
            breadcrumb() {
              const { slug } = this.$route.params.slug;

              return {
                label: slug,
                parent: "Brand",
              };
            },
          },
        },
        {
          path: "/details",
          name: "details",
          component: () => import("@/components/Brand/Details.vue"),
        },
        {
          path: "search/:searchValue",
          name: "search",
          component: () => import("@/components/SearchResult.vue"),
        },
        {
          path: "page/:slug",
          name: "pages",
          component: () => import("@/components/termsandconditions.vue"),
        },
        {
          path: "privacy-and-policy",
          name: "privacy-and-policy",
          component: () => import("@/components/privacypolicy.vue"),
        },
      ],
    },
    {
      path: "/customer",
      component: () => import("@/layouts/frontend/CustomerPage.vue"),
      children: [
        {
          path: "/",
          name: "login",
          component: () => import("@/components/Customer/Login.vue"),
          meta: {
            requiresGuest: true,
          },
        },
        {
          path: "/registration",
          name: "registration",
          component: () => import("@/components/Customer/Registration.vue"),
          meta: {
            requiresGuest: true,
          },
        },
      ],
    },
    {
      path: "/customer-dashboard",
      component: () => import("@/layouts/frontend/CustomerPage.vue"),
      children: [
        {
          path: "/",
          name: "customer_dashboard",
          component: () => import("@/components/Customer/Dashboard.vue"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/order",
          name: "customer_order",
          component: () => import("@/components/Customer/Order.vue"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/orderfromhistory",
          name: "order_from_history",
          component: () => import("@/components/Customer/OrderFromHistory.vue"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/myWishList",
          name: "my_wish_list",
          component: () => import("@/components/Customer/MyWishList.vue"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/order_details/:orderId",
          name: "order-details",
          component: () => import("@/components/Customer/OrderDetails.vue"),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/checkout",
          name: "checkout",
          component: () => import("@/components/Customer/Payment.vue"),
          // meta: {
          //   requiresAuth: true,
          // },
        },
        {
          // path: "/payment-confirm/:status/:message",
          // name: "payment-confirm",
          path: "/purchase/:status/:message",
          name: "purchase",
          component: () => import("@/components/Customer/ConfirmPayment.vue"),
          meta: {
            requiresAuth: false,
          },
        },
      ],
    },
    {
      path: "*",
      redirect: "/pages/error-404",
    },
    {
      path: "/about",
      name: "About",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "about" */ "../views/About.vue"),
    },
  ],
});
router.afterEach(() => {
  // console.log("after each");
  // Remove initial loading
  /*const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }*/
});
// router.beforeEach((to, from, next) => {
//   // Check for requiresAuth guard
//   // console.log("==beforeEach",to)
//   if (to.matched.some(record => record.meta.requiresAuth)) {
//     // Check if NO logged user
//     if (!store.getters.accessToken) {
//       // Go to login
//       next({
//         path: '/customer',
//         query: {
//           redirect: to.fullPath
//         }
//       })
//     } else {
//       // Proceed to route
//       next()
//     }
//   } else if (to.matched.some(record => record.meta.requiresGuest)) {
//     // Check if NO logged user
//     // console.log("=======")
//     if (store.getters.accessToken) {
//       // Go to login
//       next({
//         path: '/',
//         query: {
//           redirect: to.fullPath
//         }
//       })
//     } else {
//       // Proceed to route
//       next()
//     }
//   } else {
//     // Proceed to route
//     next()
//   }
// })
/*router.beforeEach((to)=>{
  // console.log('he?')
});*/
export default router;
